import { Button, MobileStepper } from '@mui/material';
import React from 'react';
import BackArrow from './Resources/BackArrow.svg';
import InfoIcon from './Resources/Infoicon.svg';
import './MobileView.css';

function UploadStep3({
  setUploadStep, firstname, lastname, email, setFirstName, setLastName, handleEmail, uploadDisableBtn, handleUploadButton,
}) {
  return (
    <div className="step3-div">
      <div style={{ display: 'flex', gap: '10px', cursor: 'pointer' }} onClick={() => setUploadStep(2)}>
        <img src={BackArrow} alt="back-arrow" />
        <span className="back-btn">Back</span>
      </div>
      <div className="info-div">
        <img src={InfoIcon} alt="info-icon" style={{ height: '46px', width: '46px' }} />
        <span className="info-text">Enter Information</span>
        <span className="info-text-1">Add your name and email to receive credit </span>
        <div className="info-div-1">
          <span className="info-text-label">First Name</span>
          <input type="text" className="info-input" value={firstname} onChange={(e) => setFirstName(e.target.value)} placeholder="First name" />
          <span className="info-text-label">Last Name</span>
          <input type="text" className="info-input" value={lastname} onChange={(e) => setLastName(e.target.value)} placeholder="Last name" />
          <span className="info-text-label">Email Address</span>
          <input type="text" className="info-input" value={email} onChange={(e) => handleEmail(e.target.value)} placeholder="Jsmith@gmail.com" />
        </div>
      </div>
      <div className="step3-bottom-div">
        <Button
          className="upload-btn"
          style={{ marginTop: '9%' }}
          disabled={uploadDisableBtn || !firstname}
          onClick={handleUploadButton}
          startIcon={(
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path d="M14.5 10V12.6667C14.5 13.0203 14.3595 13.3594 14.1095 13.6095C13.8594 13.8595 13.5203 14 13.1667 14H3.83333C3.47971 14 3.14057 13.8595 2.89052 13.6095C2.64048 13.3594 2.5 13.0203 2.5 12.6667V10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11.8327 5.33333L8.49935 2L5.16602 5.33333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M8.5 2V10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        )}
        >
         Upload Content
        </Button>
        <MobileStepper
          variant="dots"
          steps={3}
          position="static"
          activeStep={2}
          sx={{ maxWidth: 400, flexGrow: 1 }}
          className="step-2-mobile-stepper"
        />
      </div>
    </div>
  );
}

export default UploadStep3;
