import { Button } from '@mui/material';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import UploadaContent from './Resources/UploadContentImage.svg';
import RoomTour from './Resources/room tour.svg';
import Coconut from './Resources/coconut.svg';
import PoolView from './Resources/Pool View.svg';
import Sunset from './Resources/sunset.svg';
import Food from './Resources/food.svg';
import Outdoor from './Resources/wakling on the beach.svg';
import Beach from './Resources/swings.svg';
import RoomTour2 from './Resources/room tour 2.svg';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './MobileView.css';

function UploadStep0({
  siteData,
    handlePlay,
    handleUploadButtonClick,
    handleFileUpload, 
    fileInput,
}) {

  const handleTermsAndServiceClick = () => {
    if (siteData.termsURL != null && siteData.termsURL !== '') {
      window.open(siteData.termsURL)
    } else {
      window.open('https://gethovr.com/privacy')
    }
  }

  return (
    <div className="div-2">
      <img src={UploadaContent} alt="upload-content" />
      <span className="div-2-span-2">
        Submit Content
      </span>
      <span className="div-2-span-3">
        Show off and review your favorite experience from
        <br />
        {' '}
        <b>{siteData?.displayName}</b>
        . We may use the submitted content
        {' '}
        <br />
        on our website and/or social media.

      </span>
      <span className="div-2-span-4">
        {' '}
        Inspiration
      </span>

      <Swiper
        spaceBetween={50}
        slidesPerView={2}
        loop={true}
        style={{ width: '100%', height: '30%' }}
      >
        <SwiperSlide
          className="swiper-div-1"
          onClick={() => {
            handlePlay("8kPH84RsLln02kUFN47g4DUT7hMPO2Goe3C61H5KGT02Y");
          }}
        >
          <img className="h-[94px] object-cover	rounded-[3px]" src={RoomTour} alt="Tour of a room"/>
          <span>#room tour</span>
        </SwiperSlide>
        <SwiperSlide
          className="swiper-div-2"
          style={{ width: '102px !important' }}
          onClick={() => {
            handlePlay("rrGOvcjw69X4oRFJ78n5IY7JzLbL4yk2pz02gMlTQxVg");
          }}
        >
          <img className="h-[116px]  object-cover w-full rounded-[3px]" src={Coconut} alt="View of ocean with coconut drink" />
          <span>#beach</span>
        </SwiperSlide>
        <SwiperSlide
          className="swiper-div-1"
          onClick={() => {
            handlePlay("BB02AhdZOljUBe00JuDZbZXhg8YsddegnD6019ulWdrNP4");
          }}
        >
          <img className="h-[116px]  object-cover w-full rounded-[3px]" src={PoolView} alt="View of the pool" />
          <span>#pool</span>
        </SwiperSlide>
        <SwiperSlide
          className="swiper-div-2"
          style={{ width: '102px' }}
          onClick={() => {
            handlePlay("Paq6n2jJpo5a1l3R00DVEEd00cG8dJqL6cg00XNLBcnGWY");
          }}
        >
          <img className="h-[116px]  object-cover w-full rounded-[3px]" src={Sunset} alt="View of the sunset" />
          <span>#Sunset</span>
        </SwiperSlide>
        <SwiperSlide
          className="swiper-div-2"
          style={{ width: '102px' }}
          onClick={() => {
            handlePlay("f00ioVbgvhkFkbKtRvxZiSd0102S6kxWde01rzkZjd00UdCE");
          }}
        >
          <img className="h-[116px]  object-cover w-full rounded-[3px]" src={Food} alt="food" />
          <span>#food</span>
        </SwiperSlide>
        <SwiperSlide
          className="swiper-div-2"
          style={{ width: '102px' }}
          onClick={() => {
            handlePlay("npOdAzv1nHzWKZJ02y00P1OfL00w26nHGlc1sxngiYjK7Y");
          }}
        >
          <img className="h-[116px]  object-cover w-full rounded-[3px]" src={Outdoor} alt="Walking through water on beach" />
          <span>#outdoor</span>
        </SwiperSlide>
        <SwiperSlide
          className="swiper-div-1"
          style={{ width: '102px' }}
          onClick={() => {
            handlePlay("cyp5Tv9H4CMQf3kUoKqOVzQRGIXyUArNdVtAe601Xv8c");
          }}
        >
          <img className="h-[94px] object-cover	rounded-[3px]" src={Beach} alt="Swing on the beach" />
          <span>#beach</span>
        </SwiperSlide>
        <SwiperSlide
          className="swiper-div-1"
          style={{ width: '102px' }}
          onClick={() => {
            handlePlay("KPKIq35UhsCzvhNCKCSzBRo01JLXumlhGeRunrQ5kYmk");
          }}
        >
          <img className="h-[94px] object-cover	rounded-[3px]" src={RoomTour2} alt="Tour of the room" />
          <span>#room tour</span>
        </SwiperSlide>
      </Swiper>
      <div className="step-1-bottom-div">
        <input
          type="file"
          id="file-input"
          multiple
          style={{ display: 'none' }}
          onChange={handleFileUpload}
          ref={fileInput}
          accept=".mp4,.mov,.avi,.wmv,.jpg,.jpeg,.webp,.png"
        />
        <Button
          className="upload-btn"
          onClick={handleUploadButtonClick}
        >
          Upload Content
        </Button>
        <span className="div-2-span-5">
          By submitting, you agree to the
          {' '}
          <span
            onClick={() => handleTermsAndServiceClick()}
            onKeyDown={() => handleTermsAndServiceClick()}
            role="button"
            tabIndex={0}
            className="underline"
          >
            terms and conditions
          </span>
        </span>

      </div>

    </div>
  );
}

export default UploadStep0;
