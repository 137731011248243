import React, { useRef, useState, useEffect } from 'react';
import './MobileView.css';
import { experimentalStyled as styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog  from '@mui/material/Dialog';
import {doc, getDoc, addDoc, query, collection, getDocs, where, orderBy, updateDoc } from 'firebase/firestore';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import * as UpChunk from '@mux/upchunk';
import CheckIcon from './Resources/CheckIcon.svg';
import { config } from '../Constants/Configurations';
import { firestore } from '../Constants/FirestoreConfig';
import VideoPreview from './VideoPreview';
import UploadStep0 from './UploadStep0';
import UploadStep1 from './UploadStep1';
import UploadStep2 from './UploadStep2';
import UploadStep3 from './UploadStep3';
import { generateVideoThumbnail, getImageToCompress } from './MobileFunction';
import { handleBulkImageInputChange, handleImageInputChange } from './ImageUpload';

const defaultTags = [
  '✈️ Travel Tip',
  '🏖️ Beach',
  '🍕 Food',
  '🏩 Hotel',
  '🌇 Views',
  '🏊 Pool',
  '🛏️ Room Tour',
  '✨ Luxury',
  '🍽️ Restaurants',
  '👨‍👩‍👧‍👦 Family Friendly',
  '🍷 Bar',
  '💆‍♀️ Spa',
  '🐶 Pet Friendly',
  '🏕️ Outdoor',
  '⛰️ Hiking',
  '🎳 Amenities',
  '🏋️ Fitness Center',
  '🎁 Gift Shop',
  '🎭 Entertainment',
];

const videoFormat = ['video/mp4', 'video/ogg', 'video/quicktime', 'video/webm', 'video/mov', 'video/3gp', 'video/mpeg', 'video/mpg', 'video/mp2'];
const validFileTypes = ['image/jpeg', 'image/tiff', 'image/webp', 'image/png', 'image/gif', 'image/heic', 'image/svg', 'image/svg+xml', 'image/avif'];

export const defaultNewContent = {
  imageURL: '',
  contentType: '',
  contentTags: [],
};


const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#C5C5C5',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#F8F8F8',
  },
}));
export default function MobileView() {
  const fileInput = useRef(null);
  const [uploaded, setUploaded] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [progressModel, setProgressModel] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadStep, setUploadStep] = useState(0);
  const videoRef = useRef(null);
  const [uploadDisableBtn, setUploadDisableBtn] = useState(true);
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [videoPreview, setVideoPreview] = useState();
  const [invalidSiteId,setInvalidSiteId] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [siteData, setSiteData]= useState();
  const [newContent, setNewContent]= useState(defaultNewContent);
  const [imagesMapToCompress, setImagesMapToCompress]= useState({});


// This function will fetch Tags data from from the 
  async function fetchTagsData(siteId) {

    const tagQueryRef = query(collection(firestore, 'Tags'), where('siteId', '==', siteId), where('tagFromUGC', '==', true), orderBy('createdDate', 'desc'));
    const tagsDoc = await getDocs(tagQueryRef);
    let listOfTags = []
    if(tagsDoc.docs.length ===0){
      // If there is no tags saved data show Default tags
      listOfTags = defaultTags;
    }else{
    const allTagData = tagsDoc.docs.map((item) => ({
      id: item.id,
      ...item.data(),
    }));
    listOfTags = allTagData;
  }

    setAllTags(listOfTags)
  }

  useEffect(() => {
    if(siteData && siteData?.id){
      fetchTagsData(siteData?.id);
    }
  }, [siteData]);

  

  /**
   * Handles the process of uploading media file to the server.
   * - Initiates the upload process by fetching the URL for uploading.
   * - Utilizes UpChunk library for chunked uploading with progress tracking.
   * - Updates the upload progress and triggers the next step upon successful upload.
   */
  const uploadMediaFile = async (collectionRef,fileToUpload) => {
    // Display progress model during the upload process
    setProgressModel(true);

    // Fetch the URL for starting the public upload
    const uploadUrlResponse = await fetch(`${config.HIO_API_ENDPOINT}/brandcast/startPublicUpload`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cors: `${window.location.protocol}//${window.location.host}`,
      }),
    });

    // Parse the JSON response containing upload information
    const uploadUrlJson = await uploadUrlResponse.json();
    await updateDoc(doc(firestore, 'PublicContent', collectionRef.id), {
      muxUploadId: uploadUrlJson.uploadId,
    });
    

    // Create an UpChunk upload instance for the selected file
    const upload = UpChunk.createUpload({
      endpoint: uploadUrlJson.uploadUrl,
      file: fileToUpload,
      chunkSize: 30720, // Uploads the file in ~30 MB chunks
    });

    // Flag to ensure 'progress' event doesn't trigger after success
    const successFired = false;

    // Event listener for tracking upload progress
    upload.on('progress', (progress) => {
      // Update the upload percentage in the state
      if (!successFired) {
        setUploadPercentage(progress.detail);
      }
    });

    // Event listener for handling upload errors
    upload.on('error', (err) => {
      console.error('💥 🙀', err.detail);
      // Handle error scenarios appropriately
    });

    // Event listener for handling successful upload
    upload.on('success', async (data) => {
      // Trigger the next step after a successful upload
      await updateDoc(doc(firestore, 'PublicContent', collectionRef.id), {
        finsihedUploadToMux: true,
        uploaded : true
      });
    });

    // Event listener for handling upload attempt failures
    upload.on('attemptFailure', (failure) => {
      // Handle failure scenarios appropriately
    });
  };

  /**
   * Handles the uploading of metadata to the Firestore database after successful media upload.
   * - Constructs data object with relevant information for the uploaded content.
   * - Determines content type (Image/Video) and adjusts data accordingly.
   * - Adds the data to the 'PublicContent' collection in Firestore.
   * - Updates state to reflect the progress and success of the upload.
   * @param {string} url - The URL or identifier associated with the media upload.
   * @param {string} contentType - The type of content being uploaded (Image/Video).
   * @returns {DocumentReference} - Reference to the Firestore document containing the uploaded content data.
   */
  const uploadData = async () => {
    // Prepare data object with metadata for the uploaded content
    const dataToSave = {
      contentType : newContent.contentType,
      dateCreated: new Date(),
      title: uploadedFile?.name || '',
      siteId: siteData.id,
      uploadedFileName: uploadedFile?.name != null ? uploadedFile.name : '',
      fromUGC: true,
      archive: false,
      totalLength: 0,
      tag: selectedFilters,
      email,
      first_name: firstname,
      last_name: lastname
    };

    if(newContent.contentType ==="Video"){
      dataToSave.contentDuration =  newContent.duration;
      dataToSave.videoHeight =  newContent.videoHeight;
      dataToSave.videoWidth =  newContent.videoWidth;
    }

    // Adjust data for Image content type
    if (newContent.contentType === 'Image') {
      dataToSave.image = newContent.imageURL;
      dataToSave.imageName = uploadedFile?.name || '';
      dataToSave.imagewebp = '';
      dataToSave.uploaded = true;
    }

    const contentLibraryRef = await addDoc(collection(firestore, 'PublicContent'), dataToSave);
    // Adjust data for Video content type
    if (newContent.contentType === 'Video') {
      await uploadMediaFile(contentLibraryRef,uploadedFile)
    }

    // Add the data to the 'PublicContent' collection in Firestore

    // Update state to reflect successful upload
    setProgressModel(false);
    setUploaded(true);

    // Return the reference to the Firestore document
    return contentLibraryRef;
  };




async function handleMultipleContentsSave () {

  setProgressModel(false);
    if (!Array.isArray(newContent) || newContent?.length === 0) {
      console.error('Invalid content array.');
      return;
    }
    const contentPromises = newContent.map(async (content) => {
      const imageToCompress = getImageToCompress(imagesMapToCompress,content?.file.name);
      // Prepare data object with metadata for the uploaded content
      const dataToSave = {
        contentType : content.contentType,
        dateCreated: new Date(),
        title: content?.file.name || '',
        siteId: siteData.id,
        uploadedFileName: content?.file.name != null ? content?.file.name : '',
        fromUGC: true,
        archive: false,
        totalLength: 0,
        contentDuration : content?.duration || 0,
        tag: selectedFilters?.length > 0 || [] ,
        email,
        first_name: firstname,
        last_name: lastname
      };
  
      if (content?.videoHeight && content?.videoWidth) {
        dataToSave.videoHeight = content.videoHeight;
        dataToSave.videoWidth = content.videoWidth;
      }

      if (imageToCompress != null) {
        dataToSave.image = content?.imageURL;
        dataToSave.imageName = content.file?.name || '';
        dataToSave.imagewebp = '';
        dataToSave.uploaded = true;
        }
        // Add to colletion
      const publicColletionRef = await addDoc(collection(firestore, 'PublicContent'), dataToSave);
  
      const uploadTasks = [];
      if (content.contentType === 'Video') {
          uploadTasks.push(uploadMediaFile(publicColletionRef,content.file));
      }
      await Promise.all(uploadTasks);
    });
      await Promise.all(contentPromises);
      setProgressModel(false);
      setUploaded(true);

  }


  // Handle single file change 

  const handleSingleFileChange = async (fileUploaded) => {
    let tempNewContent = { ...newContent };
    if (videoFormat.includes(fileUploaded.type)) {
      const thumbnail = await generateVideoThumbnail(fileUploaded);
      const firstFrames = thumbnail;
      tempNewContent.videoImgSrc = firstFrames;
    }
    if (fileUploaded && !fileUploaded.name) {
      alert('Please select a audio or video file.');
      return false;
    }
    tempNewContent.file = fileUploaded;
    if (validFileTypes.includes(fileUploaded.type)) {
      // is an image upload
      tempNewContent.contentType = "Image";
      tempNewContent.imageURL = await handleImageInputChange(
        fileUploaded,
        siteData?.id
      );
    } else {
      // eslint-disable-next-line prefer-regex-literals
      tempNewContent.contentType = "Video";
      const url = URL.createObjectURL(fileUploaded);
       const videoElForSize = document.createElement('video');
       videoElForSize.src = url;
       // Listen for metadata loaded event to calculate duration, width, and height
       videoElForSize.addEventListener('loadedmetadata', () => {
        tempNewContent.videoHeight =  videoElForSize.videoHeight;
        tempNewContent.videoWidth =  videoElForSize.videoWidth;
        tempNewContent.duration =  videoElForSize.duration;
       });
    }
    setUploadedFile(fileUploaded);
    setNewContent(tempNewContent);

    setUploadStep(1);
  };


  const handleBulkFileChanges = (fileUploaded) => {

    const tempNewContent = { ...newContent };
    const ImageMap = [];
    // Process files asynchronously using Promise.all and map
    Promise.all(fileUploaded.map(async (file, index) => {
      let updatedContent = { ...tempNewContent };

      if (videoFormat.includes(file.type)) {
        const thumbnail = await generateVideoThumbnail(file);
        const firstFrames = thumbnail;
        updatedContent.videoImgSrc = firstFrames;
      }

      updatedContent.title = file?.name;
      updatedContent.file = file;
      updatedContent.id = index
    if (validFileTypes.includes(file.type)) {
        const imageURL = await handleBulkImageInputChange(
          file,
          ImageMap,
          siteData.id
        );
        updatedContent = {
          ...updatedContent,
          contentType: "Image",
          imageURL,
        };
      } else {
        updatedContent = {
          ...updatedContent,
          contentType: "Video"
        };
      }

      if (videoFormat.includes(file.type)) {
        const url = URL.createObjectURL(file);
        const videoElForSize = document.createElement('video');
        videoElForSize.src = url;

        await new Promise((resolve, reject) => {
          videoElForSize.addEventListener('loadedmetadata', () => {
            updatedContent = {
              ...updatedContent,
              videoWidth: videoElForSize.videoWidth,
              videoHeight: videoElForSize.videoHeight,
              duration: videoElForSize.duration,
            };
            resolve();
          });
          videoElForSize.addEventListener('error', reject);
        });
      }
      return updatedContent;
    }))
      .then((results) => {
        setNewContent(results)
        setImagesMapToCompress(ImageMap);
        setUploadStep(1);
      })
      .catch((error) => {
        console.error('Error processing files:', error);
      });
  };
  
  const handleFileChange = (e, fileDrop) => {
    let fileUploaded;
    const isMultiFile = !fileDrop && e.target.files.length > 1;

    if (!fileDrop) {
      fileUploaded = isMultiFile ? Array.from(e.target.files) : e.target.files[0];
    } else {
      fileUploaded = e.length > 1 ? Array.from(e) : e[0];
    }
    // Check if its bulk or single file upload
    if (fileUploaded?.length > 1) {
      handleBulkFileChanges(fileUploaded);
    } else {
      handleSingleFileChange(fileUploaded);
    }
  };



  const handleUploadButton = async () => {
    if(newContent?.length > 1){
      
      await handleMultipleContentsSave()
    }else{

      await uploadData();
    }
  };

  const handleUploadButtonClick = () => {
    fileInput.current.click(); // Trigger the file input
  };

  const handleEmail = (value) => {
    const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(value)) {
      setUploadDisableBtn(false);
    } else {
      setUploadDisableBtn(true);
    }
    setEmail(value);
  };

  const getSiteIdFromURL = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const siteIdFromParam = urlParams.get('siteId');
    if (siteIdFromParam != null) {
      let siteId = siteIdFromParam;
      const siteMetaDataRef = doc(firestore, 'siteMetaData', siteId);
      const siteMetaDataRefDoc = await getDoc(siteMetaDataRef);
      setSiteData({...siteMetaDataRefDoc.data()} , siteId);
      setInvalidSiteId(false);
    } else {
      setInvalidSiteId(true);
    }
  };

  const handlePlay = (value) => {
    setVideoPreview(value);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  useEffect(() => {
    getSiteIdFromURL();
  }, []);

  return (
    <>
      {invalidSiteId
      && (
        <div>
          Invalid site id
        </div>
      )}
      {!invalidSiteId
      && (
        <>
        <div className="mobile-view-main-div">
          {videoPreview
            ? (
              <VideoPreview
                videoPreview={videoPreview}
                setVideoPreview={setVideoPreview}
              />
            )
            : !uploaded && (
              <>
                {uploadStep === 0
                  && (
                    <UploadStep0
                     siteData={siteData}
                      handlePlay={handlePlay}
                      handleUploadButtonClick={handleUploadButtonClick}
                      handleFileUpload={handleFileChange}
                      fileInput={fileInput}
                    />

                  )}
                {uploadStep === 1 && (
                  <UploadStep1 
                  setUploadStep={setUploadStep} 
                  uploadedFile={uploadedFile} 
                  content={newContent}
                  />
                )}
                {uploadStep === 2 && (
                  <UploadStep2 
                  setUploadStep={setUploadStep} 
                  selectedFilters={selectedFilters} 
                  listOfTags =  {allTags}
                  setSelectedFilters={setSelectedFilters} />
                )}
                {uploadStep === 3 && (
                  <UploadStep3 
                  setUploadStep={setUploadStep} 
                  firstname={firstname} 
                  lastname={lastname} 
                  email={email} 
                  setFirstName={setFirstName} 
                  setLastName={setLastName} 
                  handleEmail={handleEmail} 
                  uploadDisableBtn={uploadDisableBtn} 
                  handleUploadButton={handleUploadButton} />
                )}
              </>
            )}

          {
            uploaded && (
              <div className="upload-successful-div">
                <img src={CheckIcon} alt="check-icon" height="67" width="67" />
                <span className="div-2-span-2">Submit Successful</span>
                <span className="div-2-span-3" style={{ textAlign: 'center' }}>
                  Thank you for submiting your content with us!
                  <br />
                  Please note that the content you’ve submitted may
                  <br />
                  be used on our website or social media.

                </span>
                <Button
                  className="upload-btn"
                  style={{ marginTop: '60%' }}
                  onClick={() => {
                    setUploadedFile(null);
                    setSelectedFilters([]);
                    setUploadStep(0);
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setUploadDisableBtn(true);
                    setUploaded(false);
                    setUploadPercentage(0);
                  }}

                >
                  Upload More
                </Button>
              </div>
            )
          }
        </div>
        <Dialog
          open={progressModel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="progress-model"
        >
          <div className="progress-model-div">
            <span className="progress-model-div-span"> {newContent?.length > 1 
                  ? `Uploading ${newContent.length} Files` 
                    : "Preparing to Submit"}</span>
            <BorderLinearProgress variant="determinate" value={uploadPercentage} style={{ width: '100%' }} />
          </div>
        </Dialog>
        </>   
      )}
    </>
  );
}
