export const generateVideoThumbnail = (file) => new Promise((resolve) => {
  const canvas = document.createElement('canvas');
  const video = document.createElement('video');

  // this is important
  video.autoplay = true;
  video.muted = true;
  video.src = URL.createObjectURL(file);

  video.onloadeddata = () => {
    const ctx = canvas.getContext('2d');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    video.pause();
    return resolve(canvas.toDataURL('image/png'));
  };
});

export async function ReturnBase64String(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result.toString());
    };
    reader.readAsDataURL(file);
  });
}
/**
 * Generates a random ID string consisting of upper/lower case letters, and numbers.
 * @param {number} length How long the ID should be (how many characters).
 * @returns {string} The randomly generated ID.
 */
export function generateID(length = 32) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}



export function getImageToCompress(imagesMapToCompress,fileName) {
  if (!imagesMapToCompress?.length > 0) {
    return null;
  }
  const matchingImage = imagesMapToCompress.find((image) => image.uploadedFileName === fileName);

  return matchingImage || null;
}

// eslint-disable-next-line 
export default {
  generateVideoThumbnail,
  ReturnBase64String,
  generateID,
  getImageToCompress
};
