import { Button, MobileStepper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import WhiteBackArrow from './Resources/WhiteBackArrow.svg';
import './MobileView.css';
import ThumbnailScroller from './BulkUplod/ThumbnailScroller';

function UploadStep1({ 
    setUploadStep, 
    content,
}) {

  const previewVideoRef = useRef(null);
  const [selectedMedia, setSelectedMedia] = useState({})
  const handleSelectContentPlay = (media) => {
    setSelectedMedia(media);
    if (previewVideoRef.current) {
      // Reset the video playback if a new video is selected
      previewVideoRef.current.load();
    }
  };

  const handleNextClick = ()=>{
    if(content?.length > 1){
      setUploadStep(3)
    }else{
      setUploadStep(2)
    }
  }

  useEffect(()=>{
    if(content?.length > 1){
      setSelectedMedia(content[0])
    }else{
      setSelectedMedia(content)
    }
  },[content])

  return (
    <div className="step1-div">
      <div style={{ display: 'flex', gap: '10px', cursor: 'pointer' }} onClick={() => setUploadStep(0)}>
        <img src={WhiteBackArrow} alt="back-arrow" />
        <span className="back-btn" style={{ color: '#FFFFFF' }}>Back</span>
      </div>
      {Object.keys(selectedMedia)?.length > 0&&<div className="playerStyle">
        {selectedMedia?.contentType ==="Image" ? (
          <img
            className="h-full w-full object-contain"
            src={selectedMedia.imageURL}
            alt="Thumbnail"
          />
        ) : (
          <video
            className="h-full w-full"
            width="100%"
            height="100%"
            controls
            autoPlay
            ref={previewVideoRef}
          >
            <source
              src={URL.createObjectURL(selectedMedia.file)}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
        {content?.length > 1 
        &&  <ThumbnailScroller  
            mediaItems={content}  
            handleSelectContentPlay={handleSelectContentPlay}
            selectedItem={selectedMedia}
            />}
      </div>}
      <div className="step1-bottom-div">
        <Button className="video-confirm-btn" onClick={handleNextClick}>Confirm Selection</Button>
        <MobileStepper
          variant="dots"
          steps={3}
          position="static"
          activeStep={0}
          sx={{ maxWidth: 400, flexGrow: 1 }}
          className="mobile-stepper"
        />
      </div>
    </div>
  );
}

export default UploadStep1;
