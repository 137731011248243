import { storage } from "../Constants/FirebaseConfig";
import { generateID, ReturnBase64String } from "./MobileFunction";
import {
  ref, uploadString, getDownloadURL,
} from 'firebase/storage';



export const handleImageInputChange = async (
  fileUploaded,
  setIsUploading,
  siteId
) => {
  const base64Image = await ReturnBase64String(fileUploaded);

  const newCoverImage = {
    content_type: fileUploaded.type,
    file: base64Image,
    name: `${generateID()}_${fileUploaded.name}`.replace(/[^a-z0-9.]/gi, '_'),
  };

  const storageRef = ref(storage, `PublicImages/${siteId}/${newCoverImage.name}`);

  await uploadString(storageRef, newCoverImage.file, 'data_url');
  const url = await getDownloadURL(storageRef);
  return url

};

export const handleBulkImageInputChange = async (
  fileUploaded,
  ImageMap,
  siteId
) => {

  const validFileTypes = ['image/jpeg', 'image/tiff', 'image/webp', 'image/png', 'image/gif',];
  const fileType = fileUploaded.type;

  if (!validFileTypes.includes(fileType)) {
    alert('Please select a file with a valid extension');
    return false;
  }


  const base64Image = await ReturnBase64String(fileUploaded);

  const newCoverImage = {
    content_type: fileUploaded.type,
    file: base64Image,
    name: `${generateID()}_${fileUploaded.name}`.replace(/[^a-z0-9.]/gi, '_'),
  };

  const storageRef = ref(storage, `PublicImages/${siteId}/${newCoverImage.name}`);

  await uploadString(storageRef, newCoverImage.file, 'data_url');
  const url = await getDownloadURL(storageRef);
  
  const coverImageToCompressAfterSave = {
    filePath: `${storageRef}/${siteId}/${newCoverImage.name}`,
    fileName: newCoverImage.name,
    uploadedFileName: fileUploaded.name,
  };

    ImageMap.push(coverImageToCompressAfterSave);
    return url;
};


// eslint-disable-next-line 
export default {
  handleImageInputChange,
  handleBulkImageInputChange,
};
