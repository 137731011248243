import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "..//MobileView.css"

export default function ThumbnailScroller(props) {
  const { mediaItems, handleSelectContentPlay ,selectedItem } = props
  
  return (
    <div>
      <Swiper
        spaceBetween={7}
        slidesPerView={4}
        loop={true}
        navigation
        modules={[Navigation]}
        className='thumbnailSwiper flex justify-center'
        style={{
          width: '100%',
          height: '50px',
          display: "flex !important",
        }}
      >
        {mediaItems.map((item, index) => (
          <SwiperSlide
            key={index}
            className="swiper-slide"
            style={{
              maxWidth: '47px',
              width: "47px"
            }}
            onClick={() => handleSelectContentPlay(item)}
          >
            <div 
            className='flex rounded-[2px] overflow-hidden w-full h-full'
             style={{
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)', 
              border: selectedItem?.id === item.id ? '2px solid #FFF' :'2px solid transparent',
              transition: 'border-color 0.3s ease-in-out',
              opacity : selectedItem?.id === item.id ? '1' :'0.6',
            }}
            >
              <img
                className="max-h-[47px] max-w-[47px] w-full h-full object-cover rounded-[3px]"
                src={item?.contentType === "Image" ? item.imageURL : item.videoImgSrc}
                alt='thumbnail'
              />
              </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
